// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudyModern from '../../../components/CaseStudyModern'
import pageMessages from '../../../messages/pageTitles.lang'
import metaMessages from '../../../messages/pageMetaDescriptions.lang'
import SEO from '../../../components/seo'

import messages from '../../../components/CaseStudyModern/messages/SMM/SMM.lang'

/* eslint-disable max-len */
const mainTeaserBg = (
  <StaticImage src="../../../images/casestudy/smm/Mainteaser.png" alt="" objectFit="cover" className="imageCover" />
)
const caseLogo = (
  <StaticImage src="../../../images/casestudy/smm/caseLogo.jpeg" alt="" objectFit="contain" className="image" />
)
const caseGalleryExample = (
  <StaticImage src="../../../images/casestudy/smm/Main.png" alt="" objectFit="contain" className="image" />
)
const challengeImage = (
  <StaticImage src="../../../images/casestudy/smm/challenge.png" alt="" objectFit="contain" className="image" />
)
const caseGallaryOne = (
  <StaticImage src="../../../images/casestudy/smm/gallary1.png" alt="" objectFit="contain" className="image" />
)
const caseGallaryTwo = (
  <StaticImage src="../../../images/casestudy/smm/gallary2.png" alt="" objectFit="contain" className="image" />
)
const caseGallaryThree = (
  <StaticImage src="../../../images/casestudy/smm/gallary3.png" alt="" objectFit="contain" className="image" />
)
const caseGallaryFour = (
  <StaticImage src="../../../images/casestudy/smm/gallary4.png" alt="" objectFit="contain" className="image" />
)

// Tech
const strapiImage = (
  <StaticImage src="../../../images/casestudy/smm/tech/Strapi.png" alt="" objectFit="contain" className="image" />
)
const kubernetesImage = (
  <StaticImage src="../../../images/casestudy/smm/tech/kubernetes.png" alt="" objectFit="contain" className="image" />
)
const amadeusImage = (
  <StaticImage
    src="../../../images/casestudy/smm/tech/amadeus.png"
    alt=""
    objectFit="contain"
    className="image imageAmadeus"
  />
)
const nextjsImage = (
  <StaticImage src="../../../images/casestudy/smm/tech/nextjs.png" alt="" objectFit="contain" className="image" />
)
const tsboImage = (
  <StaticImage src="../../../images/casestudy/smm/tech/TSBO.png" alt="" objectFit="contain" className="image" />
)
const freshmsImage = (
  <StaticImage src="../../../images/casestudy/smm/tech/freshMS.png" alt="" objectFit="contain" className="image" />
)
const fusionImage = (
  <StaticImage src="../../../images/casestudy/smm/tech/Fusion.png" alt="" objectFit="contain" className="image" />
)

const conclusionImage = (
  <StaticImage src="../../../images/casestudy/smm/conclusion.png" alt="" objectFit="contain" className="image" />
)
const contactImage = (
  <StaticImage src="../../../images/casestudy/smm/Rebecca.png" alt="" objectFit="cover" className="imageCover" />
)

type Props = {
  ...StyleProps
}

const data = [
  {
    type: 'MainTeaser',
    title: messages.smmMainTeaserTitle,
    logo: caseLogo,
    logoBg: 'white',
    modeSettings: {
      mode: 'dark',
      bgImg: mainTeaserBg,
      bgColour: 'white'
    }
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light'
    },
    sectionTitle: messages.smmSectionSectionTitle,
    sectionIcon: 'icon-company',
    sectionIconColour: 'accent',
    title: messages.smmSectionTitle,
    text: messages.smmSectionText,
    galleryTitle: messages.smmSectionGalleryTitle,
    galleryAlignment: 'right',
    gallery: [
      {
        img: caseGalleryExample,
        clickable: false
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    sectionIcon: 'icon-challenge',
    sectionIconColour: 'tertiary',
    sectionTitle: messages.smmChallengeTitle,
    title: messages.smmChallengeSectionTitle,
    text: messages.smmChallengeSectionText,
    galleryAlignment: 'left',
    gallery: [
      {
        img: challengeImage,
        clickable: false
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light'
    },
    sectionTitle: messages.smmImplementationTitle,
    sectionIcon: 'icon-icon-process',
    sectionIconColour: 'secondary',
    title: messages.smmImplementationSectionTitle,
    text: messages.smmImplementationSectionText,
    list: [
      {
        title: messages.smmImplementationListItem1Title,
        text: messages.smmImplementationListItem1Text
      },
      {
        title: messages.smmImplementationListItem2Title,
        text: messages.smmImplementationListItem2Text
      }
    ],
    galleryTitle: messages.smmSectionGalleryTitle,
    galleryAlignment: 'right',
    gallery: [
      {
        img: caseGallaryOne,
        clickable: true
      }
    ],
    imageRow: {
      items: [
        {
          img: caseGallaryOne
        },
        {
          img: caseGallaryTwo
        },
        {
          img: caseGallaryThree
        },
        {
          img: caseGallaryFour
        }
      ]
    }
  },
  {
    type: 'TechSection',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black',
      texture: 'light'
    },
    title: messages.smmTechSectionTitle,
    text: messages.smmTechSectionText,
    techLogos: [
      {
        img: strapiImage
      },
      {
        img: kubernetesImage
      },
      {
        img: amadeusImage
      },
      {
        img: nextjsImage
      }
    ],
    techLogosMore: true,
    productLogos: [
      {
        img: tsboImage,
        url: '/products/travelsandbox/'
      },
      {
        img: freshmsImage,
        url: '/products/freshms/'
      },
      {
        img: fusionImage,
        url: '/products/fusion/'
      }
    ]
  },
  {
    type: 'Section',
    modeSettings: {
      mode: 'light'
    },
    sectionTitle: messages.smmConclusionSectionTitle,
    sectionIcon: 'icon-conclusion',
    sectionIconColour: 'success',
    title: messages.smmConclusionTitle,
    text: messages.smmConclusionSectionText,
    buttonLabel: messages.smmConclusionButtonLabel,
    buttonLink: '/contact/',
    galleryAlignment: 'left',
    gallery: [
      {
        img: conclusionImage,
        clickable: false
      }
    ]
  },
  {
    type: 'RelatedCS',
    modeSettings: {
      mode: 'dark',
      bgColour: 'black'
    },
    relatedCases: ['plattenplaner', 'lhh', 'dertouristik', 'weekend', 'aldiana', 'bedfinder', 'etravel', 'ytravel']
  },
  {
    type: 'FloatingCTA',
    img: contactImage
  }
]

const CaseSmm = ({ styles }: Props) => {
  const { locale, formatMessage } = useIntl()

  return (
    <Layout footer={<Footer modern />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(pageMessages.TitleCaseStudySmm)}
        description={formatMessage(metaMessages.CaseStudySmmDescription)}
        lang={locale}
      />
      <CaseStudyModern data={data} />
    </Layout>
  )
}

export default applyStylingHoc({})<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseSmm)
